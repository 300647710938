import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { registerGuard } from './Guard'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: () => import('../components/features/Home/HomeComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () =>
      import('../components/master/features/Role/RoleComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/Users',
    name: 'Users',
    component: () =>
      import('../components/master/features/User/UserComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/Pages',
    name: 'Pages',
    component: () =>
      import('../components/master/features/Page/PageComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/Organization',
    name: 'Organization',
    component: () =>
      import(
        '../components/master/features/Organization/OrganizationComponent.vue'
      ),
    meta: { requiresAuth: true },
  },
  {
    path: '/OrganisationMapping',
    name: 'OrganisationMapping',
    component: () =>
      import(
        '../components/features/OrganisationMappings/OrganisationMappings.vue'
      ),
    meta: { requiresAuth: true },
  },
  {
    path: '/costcenterpermission',
    name: 'Cost Center Permissions',
    component: () =>
      import(
        '../components/master/features/CostCenterPermission/CostCenterPermissionComponent.vue'
      ),
    meta: { requiresAuth: true },
  },
  {
    path: '/salesbudget',
    name: 'Sales Budget',
    component: () =>
      import('../components/features/SalesBudget/SalesBudgets.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/activitybudget',
    name: 'Activity Budget',
    component: () =>
      import('../components/features/SalesActivities/SalesActivities.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/salesstageprobability',
    name: 'Stage Probability',
    component: () =>
      import(
        '../components/features/SalesStageProbabilities/SalesStageProbabilities.vue'
      ),
    meta: { requiresAuth: true },
  },
  {
    path: '/timecodes',
    name: 'Timecodes',
    component: () => import('../components/features/TimeCodes/TimeCodes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/paycodes',
    name: 'Paycodes',
    component: () => import('../components/features/Paycodes/Paycodes.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/hrroles',
    name: 'Roles',
    component: () =>
      import('../components/features/RoleMappings/RoleMappingComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/resourcetargets',
    name: 'Resource Targets',
    component: () =>
      import('../components/features/VehicleEfficiency/VehicleEfficiency.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/resourcemapping',
    name: 'ResourceMapping',
    component: () =>
      import('../components/features/ResourceMappings/ResourceMappings.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/account',
    name: 'Account',
    component: () =>
      import('../components/features/Account/AccountComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/balance-sheet',
    name: 'BalanceSheet',
    component: () =>
      import(
        '../components/features/BalanceSheetDesign/BalanceSheetDesign.vue'
      ),
    meta: { requiresAuth: true },
  },
  {
    path: '/balance-sheet-mapping',
    name: 'BalanceSheetMapping',
    component: () =>
      import(
        '../components/features/BalanceSheetMapping/BalanceSheetMappingComponent.vue'
      ),
    meta: { requiresAuth: true },
  },
  {
    path: '/resultsheetdesign',
    name: 'ResultSheetDesign',
    component: () =>
      import('../components/features/ResultSheetDesign/ResultSheetDesign.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/accountmapping',
    name: 'AccountMapping',
    component: () =>
      import(
        '../components/features/AccountMapping/AccountMappingComponent.vue'
      ),
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

registerGuard(router)

export default router
