import { result } from 'lodash'
import authService from '../common/auth-service'
import { AppConstants } from '../shared/appConstant'

export const getHeaders = () => {
  let token = authService.getAuthToken()
  let authType = authService.getAuthType()
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      AuthType: authType,
    },
  }
}

export const ApiRoutes = {
  commonApi: {
    GetOrganizationOptions: `${AppConstants.API_URL}/api/Common/GetOrganizationOptions`,
    OrganisationCompanies: `${AppConstants.API_URL}/api/Common/GetCompanies`,
    OrganisationProfitCenters: `${AppConstants.API_URL}/api/Common/GetProfitCenters`,
    OrganisationBusinessAreas: `${AppConstants.API_URL}/api/Common/GetBusinessAreas`,
    FetchOrganizationYear: `${AppConstants.API_URL}/api/Common/getOrganizationYear`,
    GetCompanyDivisions: `${AppConstants.API_URL}/api/Common/GetCompanyDivisions`,
    GetRepresentatives: `${AppConstants.API_URL}/api/Common/GetRepresentatives`,
    GetActivityTypes: `${AppConstants.API_URL}/api/Common/GetActivityTypes`,
    GetCustomerTypes: `${AppConstants.API_URL}/api/Common/GetCustomerTypes`,
    GetResourceCategories: `${AppConstants.API_URL}/api/Common/GetResourceCategories`,
  },
  userBasedPagesApi: {
    getUserBasedPages: `${AppConstants.API_URL}/api/page/userpages`,
  },
  basicAuthApi: {
    BasicAuthLogin: `${AppConstants.API_URL}/api/BasicAuth/BasicAuthLogin`,
    ChangePassword: `${AppConstants.API_URL}/api/User/ChangePassword`,
  },
  pageApi: {
    fetchPage: `${AppConstants.API_URL}/api/Page/getPage`,
    pageApiPath: `${AppConstants.API_URL}/api/Page`,
  },
  roleApi: {
    fetchRoles: `${AppConstants.API_URL}/api/Role/getrole`,
    fetchPage: `${AppConstants.API_URL}/api/Page/getPage`,
    rolePageApiPath: `${AppConstants.API_URL}/api/Page/RolePage`,
  },
  userApi: {
    fetchRoles: `${AppConstants.API_URL}/api/role/getrole`,
    fetchAzureADUser: `${AppConstants.API_URL}/api/user/GetUsersFromAZureAD`,
    userApiPath: `${AppConstants.API_URL}/api/user`,
    fetchUser: `${AppConstants.API_URL}/api/user/getuser`,
  },
  permissionScopeApi: {
    fetchPermissionScope: `${AppConstants.API_URL}/api/PermissionScope/getPermissionScope`,
    PermissionScopeApiPath: `${AppConstants.API_URL}/api/PermissionScope`,
  },
  costCenterPermissionApi: {
    fetchProfitCenter: `${AppConstants.API_URL}/api/CostCenterPermission/getProfitCenter`,
    fetchCostCenterPermission: `${AppConstants.API_URL}/api/CostCenterPermission/getCostCenterPermission`,
    costCenterPermissionApiPath: `${AppConstants.API_URL}/api/CostCenterPermission`,
    deleteCostCenterPermission: `${AppConstants.API_URL}/api/CostCenterPermission/DeleteCostCenterPermission`,
  },
  organizationApi: {
    fetchOrganization: `${AppConstants.API_URL}/api/Organization/getOrganization`,
    OrganizationApiPath: `${AppConstants.API_URL}/api/Organization`,
  },
  organisationMappingApi: {
    getAllOrganisationMappings: `${AppConstants.API_URL}/api/OrganisationMapping/GetOrganisationMappings`,
    OrganisationMapping: `${AppConstants.API_URL}/api/OrganisationMapping`,
    OrganisationMappingCompanies: `${AppConstants.API_URL}/api/OrganisationMapping/GetCompanies`, //tofixerror
    OrganisationMappingProfitCenters: `${AppConstants.API_URL}/api/OrganisationMapping/GetProfitCenters`, //tofixerror
    OrganisationMappingBusinessAreas: `${AppConstants.API_URL}/api/OrganisationMapping/GetBusinessAreas`, //tofixerror
  },
  salesBudgetApi: {
    getSalesDivisionBudget: `${AppConstants.API_URL}/api/SalesBudget/GetSalesDivisionBudget`,
    getSalesRepBudget: `${AppConstants.API_URL}/api/SalesBudget/GetSalesRepBudget`,
    postSalesDivisionBudget: `${AppConstants.API_URL}/api/SalesBudget/PostSalesDivisionBudget`,
    postSalesRepBudget: `${AppConstants.API_URL}/api/SalesBudget/PostSalesRepBudget`,
  },
  salesActivitiesApi: {
    getSalesActivities: `${AppConstants.API_URL}/api/SalesActivities/GetSalesActivities`,
    postSalesActivities: `${AppConstants.API_URL}/api/SalesActivities/PostSalesActivities`,
  },
  salesStageProbabilitiesApi: {
    getSalesStageProbabilities: `${AppConstants.API_URL}/api/SalesStageProbabilities/GetSalesStageProbabilities`,
    postSalesStageProbabilities: `${AppConstants.API_URL}/api/SalesStageProbabilities/PostSalesStageProbabilities`,
  },
  timeCodesApi: {
    getTimeCodes: `${AppConstants.API_URL}/api/TimeCodes/GetTimeCodes`,
    postTimeCodes: `${AppConstants.API_URL}/api/TimeCodes/PostTimeCodes`,
  },
  paycodesApi: {
    getPaycodes: `${AppConstants.API_URL}/api/Paycodes/GetPaycodes`,
    postPaycodes: `${AppConstants.API_URL}/api/Paycodes/PostPaycodes`,
  },
  roleMappingPropertiesApi: {
    fetchRoleMappingPropertyValues: `${AppConstants.API_URL}/api/RoleMappingProperties/GetRoleMappingPropertyValues`,
    fetchRoleMappingProperties: `${AppConstants.API_URL}/api/RoleMappingProperties/getRoleMappingProperties`,
    RoleMappingPropertiesApiPath: `${AppConstants.API_URL}/api/RoleMappingProperties`,
    RoleMappingPropertyDeleteApiPath: `${AppConstants.API_URL}/api/RoleMappingProperties/Delete`,
    RoleMappingPropertyValueDeleteApiPath: `${AppConstants.API_URL}/api/RoleMappingProperties/DeleteValue`,
  },
  roleMappingsApi: {
    getRoleMappings: `${AppConstants.API_URL}/api/RoleMappingValues/GetRoleMappingValues`,
    postRoleMappings: `${AppConstants.API_URL}/api/RoleMappingValues/PostRoleMappingValues`,
  },
  vehicleEfficiencyApi: {
    getVehicleEfficiency: `${AppConstants.API_URL}/api/VehicleEfficiency/GetVehicleEfficiency`,
    postVehicleEfficiency: `${AppConstants.API_URL}/api/VehicleEfficiency/PostVehicleEfficiency`,
  },
  resourceMappingApi: {
    getAllResourceMappings: `${AppConstants.API_URL}/api/ResourceMapping/GetResourceMappings`,
    ResourceMapping: `${AppConstants.API_URL}/api/ResourceMapping`,
  },
  accountApi: {
    GET_YEAR_LOOKUP: `${AppConstants.API_URL}/api/account/GetYearLookup`,

    GET_ACCOUNTS: `${AppConstants.API_URL}/api/account/getaccount`,
    POST_ACCOUNT: `${AppConstants.API_URL}/api/account/Save`,
    PUT_ACCOUNT: `${AppConstants.API_URL}/api/account/Update`,
    DELETE_ACCOUNT: (id: number) =>
      `${AppConstants.API_URL}/api/account/Delete/${id}`,

    POST_ACCOUNT_GROUPS_ACCOUNT: `${AppConstants.API_URL}/api/account/SaveAccountGroupsAccount`,
    GET_ACCOUNTS_COUNT: (number: number) =>
      `${AppConstants.API_URL}/api/account/GetAccountsCount/${number}`,
    COPY_ACCOUNT_GROUPS_ACCOUNT: `${AppConstants.API_URL}/api/account/CopyAccountMapping`,

    Unmap_All_Incorrectly_Mapped: `${AppConstants.API_URL}/api/account/UnmapAllIncorrectlyMappedAccount`,
  },
  accountGroupAPI: {
    GET_ACCOUNT_GROUP_LOOKUP: `${AppConstants.API_URL}/api/accountgroup/GetAccountGroupFilterLookup`,
    GET_ACCOUNT_GROUPS: `${AppConstants.API_URL}/api/accountgroup/getaccountgroups`,
    POST_ACCOUNT_GROUP: `${AppConstants.API_URL}/api/accountgroup/Save`,
    PUT_ACCOUNT_GROUP: `${AppConstants.API_URL}/api/accountgroup/Update`,
    DELETE_ACCOUNT_GROUPS: (id: number) =>
      `${AppConstants.API_URL}/api/accountgroup/Delete/${id}`,
  },
  companyApi: {
    GET_COMPANIES: `${AppConstants.API_URL}/api/company/GetAll`,
  },

  balanceSheetApi: {
    GetBalanceSheets: `${AppConstants.API_URL}/api/BalanceSheet/GetBalanceSheets`,
    CreateDuplicateBalanceSheet: `${AppConstants.API_URL}/api/BalanceSheet/CreateDuplicateBalanceSheet`,
    Save: `${AppConstants.API_URL}/api/BalanceSheet/Save`,
    Update: `${AppConstants.API_URL}/api/BalanceSheet/Update`,
    Delete: (id: number) =>
      `${AppConstants.API_URL}/api/BalanceSheet/Delete/${id}`,
    GetBalanceSheetDesignHeaders: (id: number) =>
      `${AppConstants.API_URL}/api/BalanceSheetDesign/GetBalanceSheetDesignHeaders/${id}`,
    GetBalanceSheetDesignPrimaryHeaders: `${AppConstants.API_URL}/api/BalanceSheetDesign/GetBalanceSheetDesignPrimaryHeaders`,
    GetById: (id: number) =>
      `${AppConstants.API_URL}/api/BalanceSheetDesign/GetById/${id}`,
    SaveBalanceSheetDesign: `${AppConstants.API_URL}/api/BalanceSheetDesign/Save`,
    UpdateBalanceSheetDesign: `${AppConstants.API_URL}/api/BalanceSheetDesign/Update`,
    UpdateBalanceSheetDesignOrdinal: `${AppConstants.API_URL}/api/BalanceSheetDesign/UpdateBalanceSheetDesignOrdinal`,
    DeleteBalanceSheetDesign: (id: number) =>
      `${AppConstants.API_URL}/api/BalanceSheetDesign/Delete/${id}`,
  },

  resultsheetApi: {
    GET_RESULT_SHEET: `${AppConstants.API_URL}/api/resultsheet/GetResultSheets`,
    POST_DUPLICATE: `${AppConstants.API_URL}/api/resultsheet/createduplicateresultsheet`,
    POST: `${AppConstants.API_URL}/api/resultsheet/Save`,
    PUT: `${AppConstants.API_URL}/api/resultsheet/Update`,
    DELETE: (id: number) =>
      `${AppConstants.API_URL}/api/resultsheet/Delete/${id}`,
  },
  resultSheetDesignApi: {
    GET_BY_ID: (id: number) =>
      `${AppConstants.API_URL}/api/resultsheetdesign/${id}`,
    HEADER_BY_ID: (id: number) =>
      `${AppConstants.API_URL}/api/resultsheetdesign/getresultsheetdesignheader/${id}`,
    PRIMARY_HEADER_BY_ID: (id: number) =>
      `${AppConstants.API_URL}/api/resultsheetdesign/getresultsheetdesignprimaryheader/${id}`,
    PUT_ORDINAL: `${AppConstants.API_URL}/api/resultsheetdesign/rsdesignordinal`,
    POST: `${AppConstants.API_URL}/api/resultsheetdesign`,
    PUT: `${AppConstants.API_URL}/api/resultsheetdesign`,
    DELETE: (id: number) =>
      `${AppConstants.API_URL}/api/resultsheetdesign/${id}`,
    IMPORT_DEFAULT: `${AppConstants.API_URL}/api/resultsheetdesign/importDefaultDesign`,
  },
  sourceApi: {
    GET_SOURCES: `${AppConstants.API_URL}/api/source/GetSources`,
    POST: `${AppConstants.API_URL}/api/source/Save`,
    PUT: `${AppConstants.API_URL}/api/source/Update`,
    DELETE: (id: number) => `${AppConstants.API_URL}/api/source/Delete/${id}`,
  },
  accountMappingApi: {
    GET_RANGE_DATA: `${AppConstants.API_URL}/api/accountmapping/GetRangeData`,
    GET_ACCOUNT_RANGE_LIST: `${AppConstants.API_URL}/api/accountmapping/GetAccountRangeList`,
    SAVE: `${AppConstants.API_URL}/api/accountmapping/Save`,
    SAVE_MULTIPLE: `${AppConstants.API_URL}/api/accountmapping/SaveMultipleAccountMapping`,
    REMOVE_ACCOUNT_RANGE: (id: number) =>
      `${AppConstants.API_URL}/api/accountmapping/RemoveAccountRange/${id}`,
  },
  organizationViewApi: {
    GET_PROFIT_CENTERS: `${AppConstants.API_URL}/api/OrganizationView/getAll`,
  },
}
