import { result } from 'lodash'
const en = {
  //Common
  update: 'Update',
  save: 'Save',
  remove: 'Remove',
  addNew: 'Add New',
  add: 'Add',
  ok: 'OK',
  cancel: 'Cancel',
  close: 'Close',
  actions: 'Actions',
  all: 'All',
  edit: 'Edit',
  delete: 'Delete',
  selectAll: 'Select All',
  swedish: 'Swedish',
  english: 'English',
  yes: 'Yes',
  no: 'No',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  msgSureWantToDelete: 'Are you sure want to delete?',
  msgSave: 'Save information successfully!',
  msgUpdate: 'Update information successfully!',
  msgDelete: 'Delete information successfully!',
  msgUnauthorized: "You're Unauthorized! Please contract to the administrator.",
  msgForbidden: 'Forbidden!!',
  msgNotFound: 'Not Found!!',
  msgServerError: 'Server Error!!',
  confirmationDialog: 'Confirmation Dialog',
  confirmDeleteContent: 'Are you sure want to delete?',
  msgProvideAllData: 'Please provide all data!',
  home: 'Home',
  templates: 'Templates',
  roles: 'Roles',
  users: 'Users',
  pages: 'Pages',
  page: 'Page',
  msgSelectParent: 'Select Parent',
  title: 'Title',
  icon: 'Icon',
  ordinal: 'Ordinal',
  parent: 'Parent',
  active: 'Active',
  'active (manual override)': 'Active (Manual Override)',
  organizations: 'Organizations',
  'cost center permission': 'Cost Center Permission',
  'access management': 'Access Management',
  'add new': 'Add New',
  'role name': 'Role Name',
  msgSelectRole: 'Select Roles',
  'role display name': 'Role Display Name',
  'permission scope': 'Permission Scope',
  user: 'User',
  full: 'Full',
  permission: 'Permission',
  permissions: 'Permissions',
  first: 'First',
  last: 'Last',
  name: 'Name',
  email: 'Email',
  select: 'Select',
  'welcome to sortera': 'Welcome to Sortera',
  'Company Name': 'Company Name',
  'Company Number': 'Company Number',
  'Profit Center Name': 'Profit Center Name',
  'Profit Center Code': 'Profit Center Code',
  'Business Unit Name': 'Business Unit Name',
  Division: 'Division',
  'Business Area Code': 'Business Area Code',
  'Business Area Name': 'Business Area Name',
  Country: 'Country',
  Source: 'Source',
  'Profit Center Key': 'Profit Center Key',
  'expand all': 'Expand All',
  'collapse all': 'Collapse All',
  'organisation mapping': 'Organisation Mapping',
  company: 'Company',
  object: 'Object',
  system: 'System',
  source: 'Source',
  organisations: 'Organisations',
  organisation: 'Organisation',
  'destination company': 'Destination Company',
  'destination profit center': 'Destination Profit Center',
  'destination business area': 'Destination Business Area',
  'select destination company': 'Select Destination Company',
  'select destination profit center': 'Select Destination Profit Center',
  'select destination business area': 'Select destination Business Area',
  mapping: 'Mapping',
  'master table': 'Master Table',
  hr: 'HR',
  paycodes: 'Paycodes',
  'time codes': 'Time Codes',
  kpi: 'KPI',
  sales: 'Sales',
  'sales stage probability': 'Sales Stage Probability',
  'activity budget': 'Activity Budget',
  'sales budget': 'Sales Budget',
  operations: 'Operations',
  'vehicle efficiency': 'Vehicle Efficiency',
  'select year': 'Select Year',
  'select company': 'Select Company',
  'select division': 'Select Division',
  'division budget': 'Division Budget',
  'sales representative budget': 'Sales Representative Budget',
  Company: 'Company',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  'Division Budget Saved Successfully': 'Division Budget Saved Successfully',
  'Invalid Inputs. Negative values not allowed':
    'Invalid Inputs. Negative values not allowed',
  'Representative Budget Saved Successfully':
    'Representative Budget Saved Successfully',
  'select representative': 'Select Representative',
  representative: 'Representative',
  'activity type': 'Activity Type',
  'customer type': 'Customer Type',
  'Acitivity Budget Saved Successfully': 'Acitivity Budget Saved Successfully',
  'Stage Probabilities Saved Successfully':
    'Stage Probabilities Saved Successfully',
  stage: 'Stage',
  probability: 'Probability',
  probabilities: 'Probabilities',
  'time code': 'Time Code',
  'time code name': 'Time Code Name',
  'absence time': 'Absence Time',
  'available time': 'Available Time',
  'include in FTE': 'Include In FTE',
  'Time Codes Saved Successfully': 'Time Codes Saved Successfully',
  'company name': 'Company Name',
  'company number': 'Company Number',
  paycode: 'Paycode',
  'paycode name': 'Paycode Name',
  'gross salary': 'Gross Salary',
  'include in cost': 'Include In Cost',
  benefit: 'Benefit',
  properties: 'Properties',
  property: 'Property',
  'total items': 'Total Items',
  'select resource category': 'Select Resource Category',
  'resource category code': 'Resource Category Code',
  'resource category name': 'Resource Category Name',
  'resource type': 'Resource Type',
  resource: 'Resource',
  type: 'Type',
  code: 'Code',
  region: 'Region',
  'resource mapping': 'Resource Mapping',
  'resource targets': 'Resource Targets',

  financial: 'Financial',
  reporting: 'Reporting',
  account: 'Account',

  balance: 'Balance',
  sheet: 'Sheet',
  design: 'Design',
  back: 'Back',
  range: 'Range',
  manage: 'Manage',
  default: 'Default',
  start: 'Start',
  end: 'End',
  specific: 'Specific',
  row: 'Row',
  costCenterLevel: 'Cost Center Level',
  msgSureDeleteHeaderSubHeader:
    'Are you sure delete this header with all sub headers?',
  msgSureDeleteSubHeader: 'Are you sure delete this sub header?',
  msgSureDeleteResultSheet: 'Are you sure want to delete result sheet',
  msgSureDuplicateResultSheet: 'Are you sure want to duplicate result sheet',
  msgSureDeleteBalanceSheet: 'Are you sure want to delete balance sheet',
  msgSureDuplicateBalanceSheet: 'Are you sure want to duplicate balance sheet',
  msgNoRecordsToDisplay: 'No records to display',
  header: 'Header',
  subHeader: 'Sub Header',
  bold: 'Bold',
  extraBold: 'Extra Bold',
  addNewRow: 'Add New Row',
  duplicateConfiramtion: 'Duplicate Confirmation',
  deleteConfiramtion: 'Delete Confirmation',
  balanceSheetDesignRow: 'Balance Sheet Design Row',
  'financial reporting': 'Financial Reporting',
  'balance sheet design': 'Balance Sheet Design',
  'balance sheet mapping': 'Balance Sheet Mapping',
  msgSelectHeader: 'Select Header',
  msgSelectSubHeader: 'Select Sub Header',
  msgSelectBalanceSheet: 'Select Balance Sheet',
  msgSelectSource: 'Select Source',
  rangeStart: 'Range Start',
  rangeEnd: 'Range End',
  msgSelectAccountGroup: 'Select Account Group',
  accountGroup: 'Account Group',
  year: 'Year',
  copyAccountMapping: 'Copy Account Mapping',
  isInternal: 'Is Internal',
  isRevenue: 'Is Revenue',
  msgAllCompany: 'All Company',
  projectNumber: 'Project Number',
  description: 'Description',
  sRU: 'SRU',
  vATCode: 'VAT Code',
  balanceBroughtForward: 'Balance Brought Forward',
  balanceCarriedForward: 'Balance Carried Forward',
  syncDate: 'Sync Date',
  isActive: 'Is Active',
  msgSelectCompany: 'Select Company',
  from: 'From',
  to: 'To',
  copy: 'Copy',
  'p&l design': 'P&L Design',
  'p&l mapping': 'P&L Mapping',
  'p&lDesign': 'P&L Design',
  'p&lMapping': 'P&L Mapping',
  msgSelectResultSheet: 'Select Result Sheet',
  manageResultSheet: 'Manage Result Sheet',
  resultSheet: 'Result Sheet',
  isDefault: 'Is Default',
  resultSheetDesignRow: 'Result Sheet Design Row',
  rowName: 'Row Name',
  showSubHeader: 'Show Sub Header',
  isProfitLevel: 'Is Profit Level',
  isProfitLevelPercentage: 'Is Profit Level Percentage',
  sign: 'Sign',
  mapMultiple: 'Map Multiple',
  addSpecificSubHeader: 'Add Specific Sub Header',
  msgSelectSpecificSubHeader: 'Select Specific Sub Header',
  msgSelectCostCenterGroup: 'Select Specific Organization',
  mapSelected: 'Map Selected',
  allAccountGroup: 'Select Account Group',
  invalidAccountMappings: 'Invalid Account Mappings',
  allYear: 'Select Year',
  selectCompany: 'Select Company',
  number: 'Number',
  accountGroups: 'Account Groups',
}

export default en
